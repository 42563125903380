// @flow

import * as React from "react"
import { goto, querystringSet } from "helpers/navigation"
import CheckboxSwitch from "components/CheckboxSwitch"
import Label from "components/Label"
import styles from "./styles.module.scss"

type NavigateOnToggleProps = {
  checked: boolean,
  id: string,
  label: string,
  path: string,
  // if you want something removed from the url query parameters when you toggle,
  // add it here, eg ["user_id"]
  reset?: Array<string>,
}

function removePathsFromUrl(url: string, paths: Array<string>): string {
  return paths.reduce((currentUrl, path) => querystringSet(currentUrl, path, null), url)
}

export default function NavigateOnToggleFilter(props: NavigateOnToggleProps): React.Element<"div"> {
  const [disabled, setDisabled] = React.useState(false)
  const [checked, setChecked] = React.useState(props.checked)

  const handleChange = function handleChange(event) {
    if (!disabled) {
      setChecked(!checked)
      setDisabled(true)
      const url = removePathsFromUrl(window.location.href, props.reset || [])
      const target = querystringSet(url, props.path, props.checked ? null : String(true))
      goto(target)
    }
  }

  return (
    <div className={styles.controlGroup}>
      <div className={styles.switch}>
        <CheckboxSwitch
          defaultChecked={checked}
          disabled={disabled}
          name={props.id}
          onUpdate={handleChange}
          testId={`testid-${props.id}`}
        />
      </div>
      <div className={styles.label}>
        <Label htmlFor={props.id} text={props.label} />
      </div>
    </div>
  )
}

NavigateOnToggleFilter.defaultProps = {
  reset: ([]: Array<string>),
}
