/* @flow */

import * as React from "react"
import cn from "classnames"
import { t as globalT } from "helpers/i18n"
import Request from "helpers/request"
import * as Routes from "helpers/routes"

import Icon from "components/Icon"
import FilterSelect from "components/Select/FilterSelect"

import styles from "./styles.module.scss"

type Props = {
  page: string,
  timesheetStart: string,
}

type Option = {|
  label: string,
  // eslint-disable-next-line flowtype/no-weak-types
  options: Array<any>,
  value: null,
|}

const StaffSwitcher = ({ page, timesheetStart }: Props): null | React.Element<"div"> => {
  const [staffs, setStaffs] = React.useState<$ReadOnlyArray<Option>>([])
  const [loaded, setLoaded] = React.useState(false)
  const [userCount, setUserCount] = React.useState(0)
  const [showFilter, setShowFilter] = React.useState(false)

  React.useEffect(() => {
    Request.post(Routes.relevant_staff_path(), { page, timesheet_start: timesheetStart }).then((response) => {
      const { users } = response.data
      setLoaded(true)
      setUserCount(users.length)
      setStaffs([{ label: globalT("js.users.staff_switcher.select_staff"), options: users, value: null }])
    })
  }, [page, timesheetStart])

  // only display filter once data has loaded.
  // if only a single user available, don't display useless filter.
  if (!loaded || userCount <= 1) {
    return null
  }

  const handleClickIcon = () => () => {
    setShowFilter(true)
  }

  const handleChange = () => (evt) => {
    window.location.href = evt.target.value
  }

  const handleClose = () => () => {
    setShowFilter(false)
  }

  const renderIcon = () => (
    <button
      className={cn(styles.IconContainer, { [styles.Disabled]: !loaded })}
      disabled={!loaded}
      onClick={handleClickIcon()}
      onKeyDown={handleClickIcon()}
    >
      <div className="inline-block">
        <Icon color={loaded ? "primary" : "grey"} type="people" />
      </div>
      <div className="inline-block">
        <Icon color={loaded ? "primary" : "grey"} type="arrow-drop-down" />
      </div>
    </button>
  )

  const renderFilter = () => (
    <FilterSelect
      disabled={!loaded}
      onChange={handleChange()}
      onClose={handleClose()}
      options={staffs}
      placeholder={globalT("js.users.staff_switcher.switch")}
      startOpen
    />
  )

  return <div className={styles.SwitcherWrapper}>{showFilter ? renderFilter() : renderIcon()}</div>
}

StaffSwitcher.defaultProps = { page: "user_profile", timesheetStart: "" }

export default StaffSwitcher
