// @flow

import * as React from "react"

import { goto, querystringSet } from "helpers/navigation"

import InputLabel from "components/Label/InputLabel"
import BaseFilterSelect from "components/Select/FilterSelect/BaseFilterSelect"
import StaffSelectOption from "./Option"

const getInputLabel = (label) => {
  if (!label) {
    return null
  }

  return <InputLabel text={label} />
}

const onChange = (value, path, preventGenerateReport) => {
  const url = querystringSet(window.location.href, "page", null)
  let target = querystringSet(url, path, value == null ? null : String(value))
  if (preventGenerateReport) {
    target = querystringSet(target, "prevent_generate", "true")
  }
  goto(target)
}

type SomeOfTheProps = {|
  label?: string,
  listAutoWidth?: boolean,
  path: string,
  preventGenerateReport?: boolean,
  size?: "s" | "m" | "l",
  value?: string | number,
|}

export default function NavigateOnSelectFilter(props: SomeOfTheProps): React.Element<"div"> {
  console.log(props)
  const { path, label, size, listAutoWidth, preventGenerateReport, ...rest } = props

  // We only want to log if the filter is active
  if (props.value) {
  }

  return (
    <div>
      {getInputLabel(label)}
      <BaseFilterSelect
        options={[]}
        {...rest}
        listAutoWidth={listAutoWidth}
        onChange={(e) => onChange(e.target.value, path, preventGenerateReport)}
        Option={StaffSelectOption}
        size={size}
      />
    </div>
  )
}

NavigateOnSelectFilter.defaultProps = {
  label: null,
  size: "m",
  listAutoWidth: true,
  preventGenerateReport: false,
  value: null,
}
