// @flow

/**
 Loads components onto the global so that they can be referenced by legacy code
 This should be used sparingly so we can not load extra javascript the client
 doesn't need. And, of course, where possible you should be writing these things in React

 To add a new component, import it and put it in below the others.
 */

import { extend } from "lodash"

import ModeOfPaymentForm from "upgrade_plan/ModeOfPaymentForm"
import StaffSwitcher from "users/components/StaffSwitcher"
import Chart from "reports/views/Chart"
import PPTSettings from "ppt_settings"
import NavigateOnSelectFilter from "users/filters/NavigateOnSelectFilter"
import NavigateOnToggleFilter from "users/filters/NavigateOnToggleFilter"
import MetabaseReportParam from "metabase/report-param"

// RC is for ReactComponents
window.RC = extend({}, window.RC, {
  /* insert your globally exposed components here */
  ModeOfPaymentForm,
  PPTSettings,
  Chart,
  StaffSwitcher,
  NavigateOnSelectFilter,
  NavigateOnToggleFilter,
  MetabaseReportParam,
})
