// @flow
import * as React from "react"
import FilterSelect from "components/Select/FilterSelect"
import * as T from "./types"

type Props = {|
  +appURL: URL,
  +pickerParam: T.PickerParam,
|}

const EMPTY_STRING = ""

function unpackValue(appURL: URL, pickerParam: T.PickerParam): string {
  return appURL.searchParams.get(pickerParam.name) || pickerParam.default || EMPTY_STRING
}

export default function PickerParam({ appURL, pickerParam }: Props): React.Node {
  const currValue = unpackValue(appURL, pickerParam)

  const onChange = React.useCallback(
    (e) => {
      const nextValue = e.target.value

      if (nextValue === currValue) {
        return
      }

      if (nextValue != null) {
        appURL.searchParams.set(pickerParam.name, nextValue.toString())
      } else {
        appURL.searchParams.delete(pickerParam.name)
      }

      location.assign(appURL.toString())
    },
    [appURL, currValue, pickerParam]
  )

  return (
    <FilterSelect
      htmlName={pickerParam.name}
      newStyle
      onChange={onChange}
      options={pickerParam.options}
      placeholder={pickerParam.label}
      size="m"
      value={currValue}
    />
  )
}
