// @flow
import * as React from "react"
import { noop } from "lodash"
import cn from "classnames"
import Icon from "components/Icon"
import { type Props } from "components/DropdownList/DefaultOption"
import { handleOnClick, handleMouseEnter, handleMouseLeave } from "components/DropdownList/helpers/handlers"

import styles from "./styles.module.scss"

type State = {|
  isMouseOver: boolean,
|}

export default class StaffSelectOption extends React.Component<Props, State> {
  static defaultProps: {|
    hovered: boolean,
    icon: null,
    iconCustomColor: null,
    newStyle: boolean,
    noOverflow: boolean,
    onClick: (...args: $ReadOnlyArray<mixed>) => void,
    onMouseEnter: (...args: $ReadOnlyArray<mixed>) => void,
    onMouseLeave: (...args: $ReadOnlyArray<mixed>) => void,
    selected: boolean,
    selectedTop: boolean,
    updateRef: (...args: $ReadOnlyArray<mixed>) => void,
  |} = {
    hovered: false,
    onClick: noop,
    newStyle: false,
    iconCustomColor: null,
    onMouseEnter: noop,
    onMouseLeave: noop,
    icon: null,
    noOverflow: false,
    updateRef: noop,
    selected: false,
    selectedTop: false,
  }

  constructor(props: Props) {
    super(props)
    this.state = { isMouseOver: false }
  }
  handleMouseEnter: (e: SyntheticInputEvent<HTMLElement>) => void = handleMouseEnter.bind(this)
  handleMouseLeave: (e: SyntheticInputEvent<HTMLElement>) => void = handleMouseLeave.bind(this)
  // eslint-disable-next-line flowtype/no-weak-types
  handleOnClick: (event: any) => void = handleOnClick.bind(this)

  attachIdForLazyLoading(): string {
    const labelString = this.props.label.split(": ")
    const label = labelString.length > 1 ? labelString[1] : labelString[0]

    return `fs-${label.replace(/ /g, "-").toLowerCase()}`
  }

  render(): React.Element<"div"> {
    return (
      <div
        className={cn(styles.Option, styles[this.props.size], {
          [styles.noOverflow]: this.props.noOverflow,
          [styles.hovered]: this.props.hovered,
          [styles.selectedTop]: this.props.selectedTop,
        })}
        onClick={this.handleOnClick}
        onKeyPress={noop}
        onMouseLeave={this.handleMouseLeave}
        onMouseMove={this.props.hovered ? noop : this.handleMouseEnter}
        ref={this.props.updateRef}
        role="button"
        tabIndex={-1}
      >
        <div className={cn(styles.optionLabel, { [styles.newStyle]: this.props.newStyle })}>
          {this.props.icon && (
            <Icon
              color={this.props.hovered ? "white" : "grey"}
              customColor={this.props.iconCustomColor}
              padding="0 0.25rem 0 0"
              size="s"
              type={this.props.icon}
            />
          )}
          <span id={this.attachIdForLazyLoading()}>{this.props.label}</span>
          {this.props.selected && (
            <Icon color={this.props.hovered ? "white" : "primary"} padding="0 0 0 0.25rem" size="s" type={"check"} />
          )}
        </div>
      </div>
    )
  }
}
