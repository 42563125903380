/* @flow */
import * as React from "react"
import ToggleStandard from "components/ToggleStandard"
import Text from "components/Text"
import MultiFilter from "components/Select/MultiFilter"
import { type SelectEvent } from "components/Select"
import styles from "./styles.module.scss"

type Props = {
  awardName: string,
  blockRosterPublishing: boolean,
  index: number,
  onSettingUpdate: (index: number, setting: string, status: boolean | string) => void,
  shiftAcceptance: boolean,
  tagArray: Array<string>,
  userTaggedWith: Array<string>,
}

const TableRow = ({
  awardName,
  blockRosterPublishing,
  index,
  onSettingUpdate,
  shiftAcceptance,
  tagArray,
  userTaggedWith,
}: Props): React.Element<"tr"> => {
  const shiftAcceptanceChange = (applied: boolean) => {
    onSettingUpdate(index, "shift_acceptance", applied)
  }

  const blockRosterPublishingChange = (applied: boolean) => {
    onSettingUpdate(index, "block_roster_publishing", applied)
  }

  const userWithTagChange = (e: SelectEvent) => {
    onSettingUpdate(index, "user_with_tag", e.target.value)
  }

  return (
    <tr>
      <td className={styles.awardNameColumn}>{awardName}</td>
      <td>
        <ToggleStandard applied={blockRosterPublishing} color="blue" onClick={blockRosterPublishingChange} />{" "}
      </td>
      <td>
        <ToggleStandard applied={shiftAcceptance} color="blue" onClick={shiftAcceptanceChange} />{" "}
      </td>
      <td>
        <div className={styles.multiFilterWrapper}>
          <MultiFilter
            label={
              <div className={styles.textElements}>
                <Text color="light" textOverflow="ellipsis" type="tiny">
                  {userTaggedWith.join(", ")}
                </Text>
              </div>
            }
            onChange={userWithTagChange}
            options={tagArray.map((v) => ({ value: v, label: v }))}
            searchOn
            size="s"
            tabIndex={0}
            value={userTaggedWith}
          />
        </div>
      </td>
      <td />
      <td />
    </tr>
  )
}

export default TableRow
