// @flow

import * as React from "react"
import * as I18n from "helpers/i18n"
import Select from "components/Select"
import AnnualOptionButton from "../AnnualOptionButton"
import MonthlyOptionButton from "../MonthlyOptionButton"
import Prompt from "../shared/Prompt"

type Props = {
  annualPath: string,
  annualPriceNumber: string,
  cbAnnualPath: string,
  cbMonthlyPath: string,
  currency: string,
  defaultPaymentMethod: "credit_card" | "direct_debit",
  gcAnnualPath: string,
  gcMonthlyPath: string,
  monthlyPath: string,
  monthlyPriceNumber: string,
  saving: string,
  showPaymentOptions: boolean,
  showPricingAsterisk: boolean,
}

export default function ModeOfPaymentForm({
  annualPath,
  annualPriceNumber,
  cbAnnualPath,
  cbMonthlyPath,
  currency,
  defaultPaymentMethod,
  gcAnnualPath,
  gcMonthlyPath,
  monthlyPath,
  monthlyPriceNumber,
  saving,
  showPaymentOptions,
  showPricingAsterisk,
}: Props): React.Node {
  const [paymentMethod, setPaymentMethod] = React.useState(defaultPaymentMethod)

  const [paths, setPaths] = React.useState(() => {
    if (cbMonthlyPath && cbAnnualPath) {
      return { monthly: cbMonthlyPath, annual: cbAnnualPath }
    }

    switch (defaultPaymentMethod) {
      case "credit_card":
        return { monthly: monthlyPath, annual: annualPath }
      case "direct_debit":
        return { monthly: gcMonthlyPath, annual: gcAnnualPath }
      default:
        throw new Error(defaultPaymentMethod)
    }
  })

  const handlePaymentMethodChange = React.useCallback(
    (e) => {
      setPaymentMethod(e.target.value)

      if (cbMonthlyPath && cbAnnualPath) {
        return setPaths({ monthly: cbMonthlyPath, annual: cbAnnualPath })
      }

      switch (e.target.value) {
        case "credit_card":
          return setPaths({ monthly: monthlyPath, annual: annualPath })
        case "direct_debit":
          return setPaths({ monthly: gcMonthlyPath, annual: gcAnnualPath })
        default:
          throw new Error(e.target.value)
      }
    },
    [annualPath, cbAnnualPath, cbMonthlyPath, gcAnnualPath, gcMonthlyPath, monthlyPath]
  )

  return (
    <div>
      {showPaymentOptions && (
        <div>
          <Prompt text={I18n.t("js.billing.prompt.pay")} />
          <div className="options-layout compact center">
            <div id="modeOfPayment">
              <Select
                onChange={handlePaymentMethodChange}
                options={[
                  { label: I18n.t("js.billing.mode.cc"), value: "credit_card" },
                  { label: I18n.t("js.billing.mode.dd"), value: "direct_debit" },
                ]}
                placeholder={I18n.t("js.billing.mode.placeholder")}
                size="l"
                tabIndex={0}
                value={paymentMethod}
              />
            </div>
          </div>
        </div>
      )}

      <Prompt text={I18n.t("js.billing.prompt.plan")} />
      <div className="options-layout center">
        <div id="annualOption">
          <AnnualOptionButton
            currency={currency}
            link={paths.annual}
            priceNumber={annualPriceNumber}
            saving={saving}
            showPricingAsterisk={showPricingAsterisk}
          />
        </div>
        <div id="monthlyOption">
          <MonthlyOptionButton
            currency={currency}
            link={paths.monthly}
            priceNumber={monthlyPriceNumber}
            showPricingAsterisk={showPricingAsterisk}
          />
        </div>
      </div>
    </div>
  )
}
