// @flow
import * as React from "react"
import LabeledInput from "components/Input/LabeledInput"
import * as I18n from "helpers/i18n"
import * as T from "./types"

type Props = {|
  +appURL: URL,
  +numberInputParam: T.NumberInputParam,
|}

const EMPTY_STRING = ""

function unpackValue(appURL: URL, searchInputParam: T.NumberInputParam): string {
  return appURL.searchParams.get(searchInputParam.name) || searchInputParam.default || EMPTY_STRING
}

export default function NumberInputParam({ appURL, numberInputParam }: Props): React.Node {
  const defaultValue = unpackValue(appURL, numberInputParam)

  const onBlur = React.useCallback(
    (e) => {
      const currValue = unpackValue(appURL, numberInputParam)
      const nextValue = Number.isNaN(e.currentTarget.value) ? "" : e.currentTarget.value

      if (nextValue === currValue) {
        return
      }

      if (nextValue !== "") {
        appURL.searchParams.set(numberInputParam.name, nextValue.toString())
      } else {
        appURL.searchParams.delete(numberInputParam.name)
      }

      location.assign(appURL.toString())
    },
    [appURL, numberInputParam]
  )

  const onKeyUp = React.useCallback(
    (e) => {
      if (e.key === "Enter") {
        onBlur(e)
      }
    },
    [onBlur]
  )

  return (
    <LabeledInput
      defaultValue={defaultValue}
      label={numberInputParam.label}
      name={numberInputParam.name}
      onBlur={onBlur}
      onKeyUp={onKeyUp}
      placeholder={I18n.t("js.metabase.report_param.number_input.placeholder")}
      size="m"
      type="text"
    />
  )
}
