// @flow

import * as React from "react"
import OptionButton from "components/OptionButton"
import * as I18n from "helpers/i18n"
import Button from "components/Button"
import { Heading, Price } from "../shared"
import styles from "./styles.module.scss"

type Props = {
  currency: string,
  link: string,
  priceNumber: string,
  showPricingAsterisk: boolean,
}

export default function MonthlyOptionButton(props: Props): React.Node {
  const handleClick = React.useCallback(() => {
    location.assign(props.link)
  }, [props.link])

  return (
    <OptionButton onClick={handleClick} shape="rectangle">
      <Heading text={I18n.t("js.billing.options.monthly")} />
      <Price
        currency={props.currency}
        frequency={I18n.t("js.billing.options.frequency")}
        number={props.priceNumber}
        showPricingAsterisk={props.showPricingAsterisk}
      />
      <div className={styles.planButtonContainer}>
        <Button label={I18n.t("js.billing.options.monthly_button")} onClick={handleClick} type="theme" />
      </div>
    </OptionButton>
  )
}
