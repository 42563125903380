// @flow

import Flash from "components/Flash"

type Props = {|
  message?: ?string,
  type?: ?string,
|}

function alertMessage(element: Node, props: Props = { message: null, type: null }) {
  window.RH.ReactDOM.render(
    window.RH.React.createElement(Flash, { container: element, message: props.message, type: props.type }),
    element
  )
}

export default { alertMessage }
