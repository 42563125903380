/* @flow */
import * as React from "react"
import Request from "helpers/request"
import Loader from "components/Loader"
import * as Routes from "helpers/routes"
import { t } from "helpers/i18n"
import HoverIcon from "../components/HoverIcon"
import * as Types from "./types"
import TableRow from "./tableRow"
import TableHeader from "./tableHeader"
import styles from "./styles.module.scss"

type Props = {}

type State = {
  awardSettings: Array<Types.AwardSetting>,
  loading: boolean,
}

const awardSettingsDefault = [
  {
    ato_id: -1,
    award_name: "",
    shift_acceptance: false,
    block_roster_publishing: false,
    user_with_tag: [],
    tag_array: [],
  },
]

const blocking_header = (
  <div className={styles.blockingHeader}>
    {t("js.ppt_settings.blocking")}
    <div className={styles.helpIcon}>
      <HoverIcon
        tip={<div className={styles.helpTip}>{t("js.ppt_settings.blocking_description")}</div>}
        tipPosition="top"
        type="help"
      />
    </div>
  </div>
)

const headers = ["", blocking_header, "Shift Acceptance", "Applies to", "", ""]

export default class PPTSettings extends React.Component<Props, State> {
  state: State = {
    loading: false,
    awardSettings: awardSettingsDefault,
  }

  componentDidMount() {
    this.setState({ loading: true })

    Request.get(Routes.get_ppt_awards_settings_path())
      .then((res) => {
        const awardSettings = res.data.award_settings
        this.setState({ awardSettings: awardSettings, loading: false })
      })
      .catch((e) => {})
  }

  onSettingUpdate: (index: number, setting: string, status: boolean | string) => void = (
    index: number,
    setting: string,
    status: boolean | string
  ) => {
    // Do an update
    const { awardSettings } = this.state
    const data = { ato_id: awardSettings[index].ato_id, setting: setting, status: status }

    const newAwardSettings = [
      ...awardSettings.filter((r, i) => i < index),
      {
        ...awardSettings[index],
        [setting]: status,
      },
      ...awardSettings.filter((r, i) => i > index),
    ]

    Request.put(Routes.update_ppt_awards_settings_path(), data)
      .then((res) => {
        this.setState({ loading: false, awardSettings: newAwardSettings })
      })
      .catch((e) => {})
  }

  render(): React.Element<"div"> {
    return (
      <div className={styles.PPTSettingsContainer}>
        {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
        <h2>PPT Settings</h2>
        {!this.state.loading ? (
          <div className={styles.tableWrapper}>
            <table className={styles.settingsTable}>
              <thead>
                {/* $FlowFixMe */}
                <TableHeader headers={headers} />
              </thead>
              <tbody>
                {this.state.awardSettings.map((settings, index) => (
                  <TableRow
                    awardName={settings.award_name}
                    blockRosterPublishing={settings.block_roster_publishing}
                    index={index}
                    key={String(index)}
                    onSettingUpdate={this.onSettingUpdate}
                    shiftAcceptance={settings.shift_acceptance}
                    tagArray={settings.tag_array}
                    userTaggedWith={settings.user_with_tag}
                  />
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    )
  }
}
