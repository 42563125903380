// @flow
import * as React from "react"
import styles from "./styles.module.scss"

type Props = {
  currency: string,
  frequency: string,
  number: string,
  showPricingAsterisk: boolean,
}

export default function Price(props: Props): React.Node {
  return (
    <div className={styles.price}>
      <div className={styles.amountContainer}>
        <div className={styles.number}>{props.number}</div>
        <div className={styles.currency}>{props.currency}</div>
      </div>
      <div className={styles.frequency}>
        {props.frequency}
        {props.showPricingAsterisk && "*"}
      </div>
    </div>
  )
}
