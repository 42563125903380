// @flow

import _ from "lodash"
import moment from "moment"
import chroma from "chroma-js"
import I18n from "helpers/i18n"
import * as Routes from "helpers/routes"
import Request from "helpers/request"

_.extend(window, {
  /* globally exposed deps go here, PLEASE AVOID ADDING NEW THINGS TO THIS LIST (put new JS in webpack) */
  I18n,
  chroma,
  moment,
  _, // expose underscore globally, one day we will replace with lodash (start by putting all new JS in webpack)
  Routes,
  Request,
})
