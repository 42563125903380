// @flow
import * as React from "react"
import cn from "classnames"
import moment from "moment"
import { t } from "helpers/i18n"
import { from as formatCurrency } from "helpers/currency"
import { getAwardColor } from "helpers/award"
import styles from "./styles.module.scss"
import Segment from "./Segment"

type ReportAwardSegmentType = {
  cost: number,
  hours: number,
  min_length: string,
  name: string,
  name_secondary: string | null,
  rates: {
    multiplier?: string,
    ordinary_hours?: string,
    unpaid?: string,
  },
  type: string,
}

type TimesheetComparisonType = {
  finish: string,
  start: string,
  timesheet: Array<ReportAwardSegmentType>,
  wage_compare: Array<ReportAwardSegmentType>,
}

type Props = $ReadOnly<{|
  timesheet_breakdown: Array<TimesheetComparisonType>,
|}>

const Chart: React$AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
  const toPercent = (part: number, total: number) => (part / total) * 100

  const getSimplifiedAward = (award: ReportAwardSegmentType) => ({
    is_leave: false,
    // We always want ordinary hours allowances to be their own colour not the standard ordinary hours award colour
    is_ord_hours:
      award.rates?.ordinary_hours === "false" || award.type === "Allowance"
        ? false
        : Boolean(award.rates?.ordinary_hours),
    has_min_length: Boolean(award?.min_length),
    multiplier: award.rates?.multiplier ? Number(award.rates?.multiplier) : 1,
  })

  // This looks complicated, but all we do is reduce and compare the costs of the timesheet and the wage comparison to see which is bigger
  const calculateBestSheet = (sheet: TimesheetComparisonType) => {
    const dataToDisplay = sheet.timesheet.length || sheet.wage_compare.length
    const tsCost = sheet.timesheet.reduce((total, obj) => obj.cost + total, 0)
    const wcCost = sheet.wage_compare.reduce((total, obj) => obj.cost + total, 0)

    return [tsCost > wcCost, wcCost - tsCost, dataToDisplay > 0]
  }

  const segmentComponentConstructor = (awardSegment, percent) => (
    <Segment
      color={getAwardColor(getSimplifiedAward(awardSegment))}
      cost={awardSegment.cost}
      hours={Math.round(awardSegment.hours * 100) / 100}
      key={awardSegment.name}
      multiplier={awardSegment.rates?.multiplier}
      name={awardSegment.name}
      subtitle={awardSegment.name_secondary}
      type={awardSegment.type}
      width={percent}
    />
  )

  return props.timesheet_breakdown?.length > 0 ? (
    <div className={styles.breakdownContainer}>
      <div className={styles.titleContainer}>
        <div className={styles.dateColumn} />
        <div className={styles.timesheetColumn}>{t("js.reports.wage_compare_breakdown.timesheet_title")}</div>
        <div className={styles.costColumn} />
        <div className={styles.comparisonColumn}>{t("js.reports.wage_compare_breakdown.compared_title")}</div>
      </div>
      {props.timesheet_breakdown.map((sheet, i) => {
        const [isCompliant, breakdownValue, dataToDisplay] = calculateBestSheet(sheet)
        const totalTimesheetHours = sheet.timesheet?.reduce((acc, current) => acc + current.hours, 0)
        const totalWageCompareHours = sheet.wage_compare?.reduce((acc, current) => acc + current.hours, 0)

        return (
          <div className={styles.chartsContainer} key={`${sheet.start} - ${sheet.finish}`}>
            <div className={styles.dateColumn}>
              {moment(sheet.start).format("MMM DD")} - {moment(sheet.finish).format("MMM DD")}
            </div>
            <div className={cn(styles.Chart, styles.timesheetColumn)}>
              {dataToDisplay && totalTimesheetHours !== 0 ? (
                sheet.timesheet.map((awardSegment, index) =>
                  segmentComponentConstructor(awardSegment, `${toPercent(awardSegment.hours, totalTimesheetHours)}%`)
                )
              ) : (
                <div className={styles.nullValueBreakdown} />
              )}
            </div>
            <div
              className={cn(styles.costColumn, {
                [styles.compliant]: isCompliant && dataToDisplay,
                [styles.nullValue]: !dataToDisplay || breakdownValue === 0,
              })}
            >
              {`
                  ${dataToDisplay && breakdownValue !== 0 ? (isCompliant ? "+" : "-") : ""}
                  ${formatCurrency(Math.abs(breakdownValue), 2, `${window.i18n_props.currency.unit} `)}
                `}
            </div>
            <div className={cn(styles.Chart, styles.comparisonColumn)}>
              {dataToDisplay && totalWageCompareHours !== 0 ? (
                sheet.wage_compare.map((awardSegment, index) =>
                  segmentComponentConstructor(awardSegment, `${toPercent(awardSegment.hours, totalWageCompareHours)}%`)
                )
              ) : (
                <div className={styles.nullValueBreakdown} />
              )}
            </div>
          </div>
        )
      })}
    </div>
  ) : (
    <div className={styles.noSheets}>{t("js.reports.wage_compare_breakdown.no_sheets")}</div>
  )
})

Chart.displayName = "Chart"
export default Chart
