// @flow
import * as React from "react"
import moment from "moment"
import DateRangePicker from "components/DatePicker/DateRangePickerWrapper"
import * as T from "./types"

type Props = {|
  +appURL: URL,
  +dateRangeParam: T.DateRangeParam,
|}

function unpackValue(appURL: URL, name: string, fallback: () => moment): moment {
  const value = appURL.searchParams.get(name)

  return value != null ? moment(value) : fallback()
}

export default function DateRangeParam({ appURL, dateRangeParam }: Props): React.Node {
  // fallback dates should match defaults in Metabase::DateRangeParam#render_params
  const startDate = unpackValue(appURL, dateRangeParam.names[0], () =>
    dateRangeParam.default && dateRangeParam.default.start_date
      ? moment(dateRangeParam.default.start_date)
      : moment().subtract({ week: 1 })
  )
  const endDate = unpackValue(appURL, dateRangeParam.names[1], () =>
    dateRangeParam.default && dateRangeParam.default.finish_date ? moment(dateRangeParam.default.finish_date) : moment()
  )

  const onDatesChange = React.useCallback(
    ({ startDate, endDate }) => {
      appURL.searchParams.set(dateRangeParam.names[0], startDate.format("YYYY-MM-DD"))
      appURL.searchParams.set(dateRangeParam.names[1], endDate.format("YYYY-MM-DD"))

      location.assign(appURL.toString())
    },
    [appURL, dateRangeParam]
  )

  return (
    <DateRangePicker
      endDate={moment(endDate)}
      onDatesChange={onDatesChange}
      placeholder={dateRangeParam.label}
      startDate={moment(startDate)}
    />
  )
}
