// @flow
import * as React from "react"
import SearchInput from "components/Input/SearchInput"
import * as T from "./types"

type Props = {|
  +appURL: URL,
  +searchInputParam: T.SearchInputParam,
|}

const EMPTY_STRING = ""

function unpackValue(appURL: URL, searchInputParam: T.SearchInputParam): string {
  return appURL.searchParams.get(searchInputParam.name) || searchInputParam.default || EMPTY_STRING
}

export default function SearchInputParam({ appURL, searchInputParam }: Props): React.Node {
  const [value, setValue] = React.useState(unpackValue(appURL, searchInputParam))

  const onBlur = React.useCallback(
    (e) => {
      const currValue = unpackValue(appURL, searchInputParam)
      const nextValue = e.currentTarget.value.trim()

      if (nextValue === currValue) {
        return
      }

      if (nextValue !== "") {
        appURL.searchParams.set(searchInputParam.name, nextValue.toString())
      } else {
        appURL.searchParams.delete(searchInputParam.name)
      }

      location.assign(appURL.toString())
    },
    [appURL, searchInputParam]
  )

  const onChange = React.useCallback(
    (e) => {
      setValue(e.currentTarget.value)
    },
    [setValue]
  )

  const onKeyUp = React.useCallback(
    (e) => {
      if (e.key === "Enter") {
        onBlur(e)
      }
    },
    [onBlur]
  )

  return (
    <SearchInput
      htmlName={searchInputParam.name}
      onBlur={onBlur}
      onChange={onChange}
      onKeyUp={onKeyUp}
      placeholder={searchInputParam.label}
      size="m"
      value={value}
    />
  )
}
