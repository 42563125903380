/* @flow */
import * as React from "react"
import styles from "./styles.module.scss"

type Props = {
  headers: Array<string>,
}

const TableHeader = ({ headers }: Props): React.Element<"tr"> => (
  <tr>
    {headers.map((header: string, index: number) => (
      <th className={[index === 0 ? styles.awardNameColumn : null]} key={""}>
        {header}
      </th>
    ))}
  </tr>
)

export default TableHeader
