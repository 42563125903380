// @flow

/**
  Loads helpers onto the global so that they can be referenced by legacy code
  This should be used sparingly so we can not load extra javascript the client
  doesn't need. And, of course, where possible you should be writing these things
  in React

  To add a new helper, import it and put it in below the others.
 */

import { extend } from "lodash"
import * as React from "react"
import ReactDOM from "react-dom"

// RH is for ReactHelpers
window.RH = extend({}, window.RH, {
  /* insert your globally exposed helpers here */
  React,
  ReactDOM,
})
