// @flow
import * as React from "react"
import * as T from "./types"
import DateRangeParam from "./date-range-param"
import NumberInputParam from "./number-input-param"
import PickerParam from "./picker-param"
import SearchInputParam from "./search-input-param"

type Props = {|
  +reportParam: T.ReportParam,
|}

export default function ReportParam({ reportParam }: Props): React.Node {
  const appURL = new URL(location.href)

  switch (reportParam.type) {
    case "date/range":
      return <DateRangeParam appURL={appURL} dateRangeParam={reportParam} />
    case "input/number":
      return <NumberInputParam appURL={appURL} numberInputParam={reportParam} />
    case "input/search":
      return <SearchInputParam appURL={appURL} searchInputParam={reportParam} />
    case "picker/allowance":
    case "picker/location":
    case "picker/user":
    case "picker/users_award_tag":
    case "picker/users_leave_type":
      return <PickerParam appURL={appURL} pickerParam={reportParam} />
    default:
      return null
  }
}
