// @flow
import * as React from "react"
import { AutoHoverBubble } from "components/Bubble"
import { t } from "helpers/i18n"
import { from as formatCurrency } from "helpers/currency"
import styles from "./styles.module.scss"

type Props = $ReadOnly<{|
  color: string,
  cost: number,
  hours: number,
  multiplier?: string,
  name: string,
  subtitle: string | null,
  type: string,
  width: string,
|}>

const Segment = (props: Props): React.Element<"div"> => (
  <div
    className={styles.awardSegment}
    displayName={"Segment"}
    style={{ width: props.width, backgroundColor: props.color }}
  >
    <div className={styles.bar} />
    <AutoHoverBubble offset={0.5} position="top" width={"medium"}>
      <div className={styles.message}>{props.name}</div>
      {props.subtitle && <div className={styles.subtitle}>{props.subtitle}</div>}
      <div className={styles.section}>
        {`${t("js.reports.wage_compare_breakdown.cost")}:` +
          " " +
          formatCurrency(props.cost, 2, `${window.i18n_props.currency.unit} `)}
      </div>
      <div className={styles.section}>
        {props.type === "Allowance"
          ? `${t("js.reports.wage_compare_breakdown.allowance_hours")}: ${props.hours}`
          : `${t("js.reports.wage_compare_breakdown.hours")}: ${props.hours}h`}
      </div>
      {props.multiplier && props.type === "Award" && (
        <div className={styles.section}>
          {`${t("js.reports.wage_compare_breakdown.multiplier")}: ${String(props.multiplier)}x`}
        </div>
      )}
    </AutoHoverBubble>
  </div>
)

Segment.defaultProps = {
  color: "#e8e8e8",
  multiplier: "1.0x",
}

export default Segment
