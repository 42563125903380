// @flow
/* eslint react/require-default-props: off */
import * as React from "react"
import cx from "classnames"
import styles from "./styles.module.scss"

/**
 * An input with an inline label. Useful in compact spaces,
 * horizontal layouts or inline with text. The input has
 * controlled width that grows with the input value.
 */

type Props = {|
  +defaultValue?: ?string,
  +disabled?: ?boolean,
  +label: string,
  +name: string,
  +onBlur?: ?(SyntheticFocusEvent<HTMLInputElement>) => mixed,
  +onChange?: ?(SyntheticInputEvent<HTMLInputElement>) => mixed,
  +onKeyUp?: ?(SyntheticKeyboardEvent<HTMLInputElement>) => mixed,
  +placeholder?: ?string,
  +size: "s" | "m",
  +type: string,
  +value?: string,
|}

const MIN_SIZE = 1

function LabeledInput({ label, ...props }: Props, ref: React.Ref<"input">): React.Node {
  const inputRef = React.useRef<?HTMLInputElement>(null)

  // $FlowFixMe React.Ref not technically 100% compatible with React.useImperativehandle
  React.useImperativeHandle<?HTMLInputElement>(ref, () => inputRef.current, [inputRef])

  const [size, setSize] = React.useState(
    Math.max(props.defaultValue?.length || props.value?.length || props.placeholder?.length || 0, MIN_SIZE)
  )

  // we want the input to focus when the use clicks anywhere inside
  // the inputs presentation.
  const onClick = React.useCallback(
    (e) => {
      inputRef.current?.focus()
    },
    [inputRef]
  )

  const onChange = React.useCallback(
    (e) => {
      setSize(Math.max(e.target.value.length, MIN_SIZE))
      props.onChange?.(e)
    },
    [props]
  )

  return (
    // The <div> is presentational but we have a click handler to
    // make it easier for GUI users to focus the input. Thus we should
    // make sure we hint to screen readers that this is not really
    // a semantic element by setting `aria-hidden="true"`.
    <div aria-hidden="true" className={cx(styles.LabeledInput, styles[props.size])} onClick={onClick}>
      <label className={styles.label} htmlFor={props.name}>
        {label}
      </label>
      <input
        className={styles.input}
        defaultValue={props.defaultValue}
        disabled={props.disabled}
        name={props.name}
        onBlur={props.onBlur}
        onChange={onChange}
        onKeyUp={props.onKeyUp}
        placeholder={props.placeholder}
        ref={inputRef}
        size={size}
        type={props.type}
        value={props.value}
      />
    </div>
  )
}

export default (React.forwardRef<Props, HTMLInputElement>(LabeledInput): React$AbstractComponent<
  Props,
  HTMLInputElement
>)
